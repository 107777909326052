import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import LogRocket from 'logrocket';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private auth: AngularFireAuth) {
  //  LogRocket.init('oaryee/meeting-tracker');

  }
}
