import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MsGraphService {

  url = 'https://graph.microsoft.com/v1.0/me';

  constructor(private http: HttpClient) { }


  getGraphId = (token: string) => new Promise((resolve, reject) => {

    const headers = new Headers({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${token}`
    });

    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type':  'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${token}`
      })
    };

    this.http.get(this.url, httpOptions)
    .subscribe((res: any) => {
      resolve(res.id);
    });

  });

}
