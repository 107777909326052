import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class MeetingService {
  constructor(
    private fireStore: AngularFirestore,
    private auth: AngularFireAuth,
    private toastController: ToastController,
    private analytics: AngularFireAnalytics
  ) {}

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      color: 'primary',
      duration: 5000,
      buttons: [
        {
          text: 'Stäng',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });
    toast.present();
  }

  add = async (type: string, customer: string) => {
    this.analytics.logEvent(`meeting_type_${type}`);

    const user = await this.auth.currentUser;

    const myUser: any = await this.fireStore
      .doc(`users/${user.uid}`)
      .get()
      .toPromise();

    const meeting = {
      type,
      userID: myUser.data().graphID,
      customer,
      userDisplayName: user.displayName,
      date: new Date(),
      dateSign: `${new Date().getFullYear()}-${new Date().getMonth()}`,
    };

    await this.fireStore
      .collection('meetings')
      .add(meeting)
      .then(() => {
        this.presentToast('Ditt möte sparades! Tack!');
      });

    return 'Success';
  };

  public getOne = async (id: string) => {
    const meeting: any = await this.fireStore
      .doc(`meetings/${id}`)
      .get()
      .toPromise();

    return { ...meeting.data(), id: meeting.id };
  };

  public getAll = () => this.fireStore.collection(`meetings`).valueChanges();

  public getAllMy = async () => {
    const user = await this.auth.currentUser;

    const myUser: any = await this.fireStore
      .doc(`users/${user.uid}`)
      .get()
      .toPromise();

    const uid = myUser.data().graphID;

    const dateSign = `${new Date().getFullYear()}-${new Date().getMonth()}`;

    return this.fireStore
      .collection(`meetings`, (ref) => ref.where('userID', '==', uid))
      .valueChanges();
  };

  public sum(meetings: any[]) {
    const sum = {
      physical: 0,
      video: 0,
      lunch: 0,
      new: 0,
    };

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < meetings.length; i++) {
      const type = meetings[i].type;

      console.count();

      if (type === 'physical') {
        sum.physical++;
      }
      if (type === 'video') {
        sum.video++;
      }
      if (type === 'lunch') {
        sum.lunch++;
      }
      if (type === 'new' || type === 'ny') {
        sum.new++;
      }
    }

    return sum;
  }
}
