import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MsGraphService } from './ms-graph.service';

@Injectable({
  providedIn: 'root',
})
export class FireStoreService {
  constructor(private fireStore: AngularFirestore, private msGraph: MsGraphService,) {}

  add = (collection: string, data: string) => this.fireStore.collection(collection).add(data);

  checkGraph = () => {

    console.log('checking');

    if(!localStorage.hasGraph) {
      console.log('getting user');
this.fireStore
      .doc(`users/${localStorage.uid}`)
      .get()
      .subscribe((user: any) => {
        if (user.data()) {
          if (user.data().graphID) {

            localStorage.graphID = user.data().graphID;

            localStorage.hasGraph = true;
          }
          else {
            // get Graph Token
            this.msGraph.getGraphId(localStorage.graphToken)
            .then((id: string) => {
              this.addGraph(id)
              .then(() => {
                localStorage.hasGraph = true;
                console.log('JAG ÄR FÄRDIG! 💩');
              });
            });
          };
        }
      });
    }


    };


    addGraph = (graphID: string) => this.fireStore.doc(`users/${localStorage.uid}`).update({graphID});
}
