import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ToastController } from '@ionic/angular';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private zone: NgZone,
    private analytics: AngularFireAnalytics,
    private toastController: ToastController
  ) {}

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      color: 'danger',
      duration: 5000,
      buttons: [
        {
          text: 'Stäng',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });
    toast.present();
  }

  handleError(error: any) {
    // Check if it's an error from an HTTP response
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection; // get the error object
    }

    console.error(error);
    this.analytics.logEvent('application_error', {...error});
    console.error('Error from global error handler', error);
    this.presentToast('Något gick fel! Försök igen senare!');
  }
}




